<template>
  <div class="cmp-text-paragraphs">
    <p class="inside-cmp-text" v-for="(par, index) in comp.paragraphs" :key="index">{{ par }}</p>
  </div>
</template>

<script>
export default {
  props: ["comp"],
};
</script>