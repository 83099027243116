<template>
  <div class="footerPlaceholder" :style="'margin-bottom:' + footerHeight + 'px'"></div>
  <div class="cmp-footer" ref="footerFixed">
    <div class="footer">
      <div class="left footer-div">
        <div class="icon-text-inline">
          <Icon :size="25" color="white" icon="clock-outline" />
          <p>Program cabinet</p>
        </div>
        <ul v-if="!showPeriods">
          <li v-for="(day, index) in openingHoursDays" :key="index">
            <span class="opening-day">{{ day.dayShort }}: </span>
            <span class="opening-hours">{{ day.hours }}</span>
          </li>
        </ul>
        <ul v-else>
          <li v-for="(period, index) in openingHoursPeriods" :key="index">
            <span class="opening-period">{{ period.label }}: </span>
            <span class="opening-hours">{{ period.hours }}</span>
          </li>
        </ul>
      </div>
      <div class="center footer-div">
        <div class="icon-text-inline">
          <Icon :size="25" color="white" icon="contact" />
          <p>Contact</p>
        </div>
        <ul>
          <li class="icon-text-inline">
            <Icon color="white" icon="phone-in-call" />
            <span class="phone-format">{{ phoneNumber.displayed }}</span>
          </li>
          <li class="icon-text-inline">
            <Icon color="white" icon="email" />
            <span>{{ emailAddress.displayed }}</span>
          </li>
          <li class="icon-text-inline">
            <Icon color="white" icon="location-outline" />
            <span>{{ address }}</span>
          </li>
        </ul>
      </div>
      <div class="right footer-div">
        <div class="icon-text-inline">
          <Icon :size="25" color="white" icon="click" />
          <p>Link-uri utile</p>
        </div>
        <ul>
          <li v-for="(page, index) in navPages" :key="index">
            <router-link :to="'/' + page.url">{{ page.title }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon.vue";

import { useNavPagesStore } from "@/stores/NavPagesStore";
import { useOpeningHoursStore } from "@/stores/OpeningHoursStore";
import { useContactStore } from "@/stores/ContactStore";
import { onMounted } from "@vue/runtime-core";
import { ref } from "vue";

export default {
  components: { Icon },

  setup() {
    const openingHoursDays = useOpeningHoursStore().days;
    const openingHoursPeriods = useOpeningHoursStore().periods;
    const phoneNumber = useContactStore().phoneNumber;
    const emailAddress = useContactStore().emailAddress;
    const address = useContactStore().address;
    const showPeriods = ref(false);
    const navPages = useNavPagesStore().pages;

    let footerFixed = ref("null");
    let footerHeight = ref("10");
    onMounted(() => {
      footerHeight.value = footerFixed.value.clientHeight;
    });

    return { address, emailAddress, footerFixed, footerHeight, navPages, openingHoursDays, openingHoursPeriods, phoneNumber, showPeriods };
  },
};
</script>
