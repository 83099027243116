<template>
  <div class="page-head">
    <div
      class="page-head-bg"
      :style="{ 'background-image': 'url(' + img + ')' }"
    >
      <div class="page-head-content">
        <h1 class="pretitle">{{ title }}</h1>
        <h4 class="subtitle">{{ subtitle }}</h4>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['img', 'subtitle', 'title'],
};
</script>