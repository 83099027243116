import { defineStore } from "pinia";

export const useFAQStore = defineStore("FAQStore", {
  state: () => ({
    questions: [
      {
        question: "Care sunt orele de program ale cabinetului?",
        answers: [
          "Cabinetul este deschis în fiecare zi de luni până vineri între orele 8:00 și 20:00 și sâmbătă între orele 8:00 și 12:00.",
          "Pentru programări și în zilele de weekend sau sărbători legale, puteți face o solicitare online pe adresa de email, sau ne puteți contacta telefonic.",
        ],
      },
      {
        question: "Ce trebuie să fac înainte de o vizită la dentist?",
        answers: [
          "Trebuie să vă asigurați că aveți o programare confirmată de către cabinet, în caz contrar, contactați-ne la numărul de telefon sau la adresa de email pentru a confirma disponibilitatea.",
          // "Trebuie să faceți o igienizare a cavității bucale (spălat pe dinți, folosit ață dentară și clătit cu apă de gură.",
        ],
      },
      {
        question: "Există parcare disponibilă în apropierea cabinetului?",
        answers: [
          "Desigur, există locuri de parcare chiar în fața cabinetului."
        ],
      },
    ],
  }),
});
