<template>
  <div class="navbar">
    <div class="non-mobile-nav">
      <router-link class="nav-logo" :to="'/'" v-on:click="navOpened = false">
        <img class="nav-logo-small" src="../assets/logos/logo_small_light.svg" alt="logo" />
        <img class="nav-logo-large" src="../assets/logos/logo_large_light.svg" alt="logo" />
      </router-link>
      <ul class="nav-buttons">
        <li v-for="(page, index) in navPages" :key="index">
          <!-- <a class="nav-anchor" :href="'#' + page.id">{{ page.title }}</a> -->
          <router-link class="nav-anchor" :to="'/' + page.url">{{
            page.title
          }}</router-link>
          <div class="nav-underline"></div>
        </li>
      </ul>
      <svg
        :class="{ ham: true, active: navOpened }"
      class="burger-icon"
        viewBox="0 0 100 100"
        width="80"
        v-on:click="navOpened = !navOpened"
      >
        <path
          class="line top"
          d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
        />
        <path class="line middle" d="m 30,50 h 40" />
        <path
          class="line bottom"
          d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
        />
      </svg>
    </div>
    <div
      class="mobile-nav"
      :class="{ show: navOpened }"
      v-on:click="navOpened = !navOpened"
    >
      <ul class="nav-buttons">
        <li v-for="(page, index) in navPages" :key="index">
          <router-link class="nav-anchor" :to="'/' + page.url">{{
            page.title
          }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import useLogout from "../static/composables/useLogout";
import { ref } from "vue";

import { useNavPagesStore } from "@/stores/NavPagesStore"

export default {
  setup() {
    const { logout, error } = useLogout;

    const navPages = useNavPagesStore().pages;
    var navOpened = ref(false);

    const toggleNav = () => {
      console.log(navOpened);
      navOpened = !navOpened;
    };

    const handleLogout = async () => {
      await logout();
      if (!error.value) {
        router.push({ name: "Authentication" });
      }
    };

    return { toggleNav, handleLogout, navOpened, navPages };
  },
};
</script>
