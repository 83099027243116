<template>
  <PageHead :img="headImg" :title="headTitle" :subtitle="subtitle" />
  <div class="container-white-background">
    <div class="about-page container">
      <Separator size="75" />
      <TitleTextParagraphs :comp="aboutText" />
      <Separator size="75" />
      <TitleTextParagraphs :comp="antresText" />
      <Separator size="75" />
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import PageHead from "../components/PageHead.vue";
import Separator from "@/components/Separator.vue";
import TitleTextParagraphs from "../components/subcomponents/TitleTextParagraphs.vue";

export default {
  components: {
    PageHead,
    Separator,
    TitleTextParagraphs,
  },
  setup() {
    // #############################
    // Content displayed on the website
    // #############################
    const headImg = ref(
      `https://images.unsplash.com/photo-1616391182219-e080b4d1043a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2583&q=80`
    );
    const headTitle = ref(`Despre VisoDent`);
    const subtitle = ref("Informații despre cabinet.");
    // #############################
    // #############################

    const aboutText = ref({
      title: "VisoDent",
      titleType: 3,
      paragraphs: ["La VisoDent ne mândrim cu capacitatea noastră de a aduce îmbunătățiri semnificative în viețile celor care ne calcă pragul și de a le reda zâmbetul mult dorit. Datorită experienței și utilizării tehnologiei de vârf, ne asigurăm că oferim cele mai eficiente și precise tratamente stomatologice. Cu toate acestea, ceea ce ne diferențiază cu adevărat este modul în care ne implicăm într-un dialog constructiv cu pacienții noștri. Punem accent pe nevoile lor, oferind soluții personalizate și explicând pe înțelesul fiecăruia procedurile care vor urma, cea mai mare recompensă fiind chiar satisfacția acestora."]
    });

    const antresText = ref({
      title: "AntRES2.0",
      titleType: 3,
      paragraphs: ["SC VISODENT SRL este un start-up finanțat din Programul Capital Uman 2014-2020, prin programul “Antreprenoriat Inovativ pentru Studenți în Regiunea de Sud-Est – AntRES2.0 demarat de Universitatea Dunărea de Jos din Galați în parteneriat cu Patronatul Tinerilor Întreprinzători din Regiunea de Sud-Est, în calitate de beneficiar al proiectului.",
        "Titlul proiectului este: Visodent nr 1/29.06.2022", "Misiunea proiectului este a sprijini integrarea pe piața muncii a studenților și încurajarea acestora în realizarea de start-up-uri."]
    });

    return { aboutText, antresText, headImg, subtitle, headTitle };
  },
};
</script>