<template>
  <div
    class="cmp-separator"
    :class="{
      withLine: line,
      separator20: size == 20,
      separator40: size == 40,
      separator50: size == 50,
      separator60: size == 60,
      separator75: size == 75,
      separator80: size == 80,
      separator100: size == 100,
      separator150: size == 150,
    }"
  >
    <div class="cmp-separator-line" v-if="line"></div>
  </div>
</template>

<script>
export default {
  props: ["line", "size"],
};
</script>


<!-- .cmp-service-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;

  &:hover {
    background-color: $primaryColor05;
  }
} -->