<template>
  <div v-if="component.component == 'title'">
    <Title :comp="component" />
  </div>
  <div v-else-if="component.component == 'text-simple'">
    <TextSimple :comp="component" />
  </div>
  <div v-else-if="component.component == 'text-paragraphs'">
    <TextParagraphs :comp="component" />
  </div>
  <div v-else-if="component.component == 'title-text-simple'">
    <TitleTextSimple :comp="component" />
  </div>
  <div v-else-if="component.component == 'title-text-paragraphs'">
    <TitleTextParagraphs :comp="component" />
  </div>
  <div v-else-if="component.component == 'classic-teaser'">
    <ClassicTeaser :teaser="component" />
  </div>
  <div v-else-if="component.component == 'classic-teaser-with-list'">
    <ClassicTeaserWithList :teaser="component" />
  </div>
  <div v-else-if="component.component == 'list'">
    <List :list="component" />
  </div>
  <Separator :size="separatorSize" :line="separatorLine" />
</template>

<script>
import ClassicTeaser from "@/components/subcomponents/ClassicTeaser.vue";
import ClassicTeaserWithList from "@/components/subcomponents/ClassicTeaserWithList.vue";
import Separator from "@/components/Separator.vue";
import List from "@/components/subcomponents/List.vue";
import TextParagraphs from "@/components/subcomponents/TextParagraphs.vue";
import TextSimple from "@/components/subcomponents/TextSimple.vue";
import Title from "@/components/subcomponents/Title.vue";
import TitleTextParagraphs from "@/components/subcomponents/TitleTextParagraphs.vue";
import TitleTextSimple from "@/components/subcomponents/TitleTextSimple.vue";

export default {
  components: {
    ClassicTeaser,
    ClassicTeaserWithList,
    Separator,
    List,
    TextParagraphs,
    TextSimple,
    Title,
    TitleTextSimple,
    TitleTextParagraphs,
  },
  props: ["after", "component"],
  setup(props) {
    let separatorSize = props.after;
    let separatorLine = false;
    if (props.component.separator && props.component.separator.size) {
      separatorSize = props.component.separator.size;
    }
    if (props.component.separator && props.component.separator.line) {
      separatorLine = props.component.separator.line;
    }
    return { separatorLine, separatorSize };
  },
};
</script>