<template>
  <!-- default -->
  <svg
    class="cmp-icon"
    v-if="!icon"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M7 2C4 2 2 5 2 8c0 2.11 1 5 2 6s2 8 4 8c4.54 0 2-7 4-7s-.54 7 4 7c2 0 3-7 4-8s2-3.89 2-6c0-3-2-6-5-6s-3 1-5 1s-2-1-5-1Z"
    />
  </svg>

  <!-- arrow-down -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'arrow-down'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M12 14.975q-.2 0-.387-.075q-.188-.075-.313-.2l-4.6-4.6q-.275-.275-.275-.7q0-.425.275-.7q.275-.275.7-.275q.425 0 .7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275q.425 0 .7.275q.275.275.275.7q0 .425-.275.7l-4.6 4.6q-.15.15-.325.212q-.175.063-.375.063Z"
    />
  </svg>

  <!-- arrow-right -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'arrow-right'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M9.29 15.88L13.17 12L9.29 8.12a.996.996 0 1 1 1.41-1.41l4.59 4.59c.39.39.39 1.02 0 1.41L10.7 17.3a.996.996 0 0 1-1.41 0c-.38-.39-.39-1.03 0-1.42z"
      />
  </svg>

  <!-- cancel, reject, x -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'cancel'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="m8.4 17l3.6-3.6l3.6 3.6l1.4-1.4l-3.6-3.6L17 8.4L15.6 7L12 10.6L8.4 7L7 8.4l3.6 3.6L7 15.6Zm3.6 5q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"
    />
  </svg>

  <!-- cancel, reject, x - OUTLINE -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'cancel-outline'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="m8.4 17l3.6-3.6l3.6 3.6l1.4-1.4l-3.6-3.6L17 8.4L15.6 7L12 10.6L8.4 7L7 8.4l3.6 3.6L7 15.6Zm3.6 5q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4Q8.65 4 6.325 6.325Q4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"
    />
  </svg>

  <!-- clock -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'clock'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M14.625 16.025q.275.275.675.275t.7-.3q.275-.275.275-.7q0-.425-.275-.7l-3-3V7.975q0-.425-.287-.7Q12.425 7 12 7t-.712.287Q11 7.575 11 8v3.975q0 .2.075.387q.075.188.225.338ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"
    />
  </svg>

  <!-- click -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'click'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M11.7 18q-2.4-.125-4.05-1.85T6 12q0-2.5 1.75-4.25T12 6q2.425 0 4.15 1.65Q17.875 9.3 18 11.7l-2.1-.625q-.325-1.35-1.4-2.212Q13.425 8 12 8q-1.65 0-2.825 1.175Q8 10.35 8 12q0 1.425.863 2.5q.862 1.075 2.212 1.4Zm.3 4q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12v.45q0 .225-.05.45L20 12.3V12q0-3.35-2.325-5.675Q15.35 4 12 4Q8.65 4 6.325 6.325Q4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20h.3l.6 1.95q-.225.05-.45.05H12Zm8.175.15l-3.925-3.925l-.75 2.275q-.125.35-.475.337q-.35-.012-.475-.362L12.275 12.9q-.1-.275.125-.5q.225-.225.5-.125l7.575 2.275q.35.125.363.475q.012.35-.338.475l-2.275.75l3.925 3.925q.15.15.15.35q0 .2-.15.35l-1.275 1.275q-.15.15-.35.15q-.2 0-.35-.15Z"
    />
  </svg>

  <!-- clock - OUTLINE -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'clock-outline'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M14.625 16.025q.275.275.675.275t.7-.3q.275-.275.275-.7q0-.425-.275-.7l-3-3V7.975q0-.425-.287-.7Q12.425 7 12 7t-.712.287Q11 7.575 11 8v3.975q0 .2.075.387q.075.188.225.338ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-10Zm0 8q3.325 0 5.663-2.337Q20 15.325 20 12t-2.337-5.663Q15.325 4 12 4T6.338 6.337Q4 8.675 4 12t2.338 5.663Q8.675 20 12 20Z"
    />
  </svg>

  <!-- close -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'close'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z"
    />
  </svg>

  <!-- contact -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'contact'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M4 23v-2h16v2ZM4 3V1h16v2Zm8 10q1.25 0 2.125-.875T15 10q0-1.25-.875-2.125T12 7q-1.25 0-2.125.875T9 10q0 1.25.875 2.125T12 13Zm-8 7q-.825 0-1.412-.587Q2 18.825 2 18V6q0-.825.588-1.412Q3.175 4 4 4h16q.825 0 1.413.588Q22 5.175 22 6v12q0 .825-.587 1.413Q20.825 20 20 20Zm1.75-2q1.125-1.4 2.725-2.2q1.6-.8 3.525-.8q1.925 0 3.525.8q1.6.8 2.725 2.2H20V6H4v12Zm2.95 0h6.6q-.725-.5-1.562-.75Q12.9 17 12 17t-1.737.25q-.838.25-1.563.75Zm3.3-7q-.425 0-.712-.288Q11 10.425 11 10t.288-.713Q11.575 9 12 9t.713.287Q13 9.575 13 10t-.287.712Q12.425 11 12 11Zm0 1Z"
    />
  </svg>

  <!-- edit -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'edit'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="m19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575q.837 0 1.412.575l1.4 1.4q.575.575.6 1.388q.025.812-.55 1.387ZM17.85 10.4L7.25 21H3v-4.25l10.6-10.6Z"
    />
  </svg>

  <!-- email -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'email'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M12 22q-2.05 0-3.875-.788q-1.825-.787-3.187-2.15q-1.363-1.362-2.15-3.187Q2 14.05 2 12q0-2.075.788-3.887q.787-1.813 2.15-3.175Q6.3 3.575 8.125 2.787Q9.95 2 12 2q2.075 0 3.887.787q1.813.788 3.175 2.151q1.363 1.362 2.15 3.175Q22 9.925 22 12v1.45q0 1.475-1.012 2.512Q19.975 17 18.5 17q-.9 0-1.675-.4t-1.275-1.05q-.675.675-1.587 1.063Q13.05 17 12 17q-2.075 0-3.537-1.463Q7 14.075 7 12t1.463-3.538Q9.925 7 12 7t3.538 1.462Q17 9.925 17 12v1.45q0 .725.45 1.137q.45.413 1.05.413q.6 0 1.05-.413q.45-.412.45-1.137V12q0-3.275-2.363-5.638Q15.275 4 12 4Q8.725 4 6.362 6.362Q4 8.725 4 12t2.362 5.637Q8.725 20 12 20h5v2Zm0-7q1.25 0 2.125-.875T15 12q0-1.25-.875-2.125T12 9q-1.25 0-2.125.875T9 12q0 1.25.875 2.125T12 15Z"
    />
  </svg>

  <!-- facebook-round -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'facebook-round'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12c0 4.84 3.44 8.87 8 9.8V15H8v-3h2V9.5C10 7.57 11.57 6 13.5 6H16v3h-2c-.55 0-1 .45-1 1v2h3v3h-3v6.95c5.05-.5 9-4.76 9-9.95z"
    />
  </svg>

  <!-- facebook-square -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'facebook-square'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M15.402 21v-6.966h2.333l.349-2.708h-2.682V9.598c0-.784.218-1.319 1.342-1.319h1.434V5.857a19.19 19.19 0 0 0-2.09-.107c-2.067 0-3.482 1.262-3.482 3.58v1.996h-2.338v2.708h2.338V21H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4.598z"
    />
  </svg>

  <!-- flip-card -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'flip-card'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="m14 20l6-6V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8zM6 6h12v6h-4a2 2 0 0 0-2 2v4H6V6zm10 4H8V8h8v2z"
    />
  </svg>

  <!-- heart -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'heart'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="m12 21.35l-1.45-1.32C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5c0 3.77-3.4 6.86-8.55 11.53L12 21.35Z"
    />
  </svg>

  <!-- location -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'location'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M12 12q.825 0 1.413-.588Q14 10.825 14 10t-.587-1.413Q12.825 8 12 8q-.825 0-1.412.587Q10 9.175 10 10q0 .825.588 1.412Q11.175 12 12 12Zm0 10q-4.025-3.425-6.012-6.363Q4 12.7 4 10.2q0-3.75 2.413-5.975Q8.825 2 12 2t5.587 2.225Q20 6.45 20 10.2q0 2.5-1.987 5.437Q16.025 18.575 12 22Z"
    />
  </svg>

  <!-- location - OUTLINE -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'location-outline'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M12 12q.825 0 1.413-.588Q14 10.825 14 10t-.587-1.413Q12.825 8 12 8q-.825 0-1.412.587Q10 9.175 10 10q0 .825.588 1.412Q11.175 12 12 12Zm0 7.35q3.05-2.8 4.525-5.088Q18 11.975 18 10.2q0-2.725-1.738-4.463Q14.525 4 12 4Q9.475 4 7.737 5.737Q6 7.475 6 10.2q0 1.775 1.475 4.062Q8.95 16.55 12 19.35Zm0 2.275q-.2 0-.4-.075t-.35-.2Q7.6 18.125 5.8 15.363Q4 12.6 4 10.2q0-3.75 2.413-5.975Q8.825 2 12 2t5.587 2.225Q20 6.45 20 10.2q0 2.4-1.8 5.163q-1.8 2.762-5.45 5.987q-.15.125-.35.2q-.2.075-.4.075ZM12 10.2Z"
    />
  </svg>

  <!-- phone-in-call -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'phone-in-call'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M19 11.95q0-2.925-2.038-4.963Q14.925 4.95 12 4.95v-2q1.875 0 3.513.712q1.637.713 2.85 1.926q1.212 1.212 1.925 2.85Q21 10.075 21 11.95Zm-4 0q0-1.25-.875-2.125T12 8.95v-2q2.075 0 3.538 1.462Q17 9.875 17 11.95ZM19.95 21q-3.225 0-6.287-1.438q-3.063-1.437-5.425-3.8q-2.363-2.362-3.8-5.425Q3 7.275 3 4.05q0-.45.3-.75t.75-.3H8.1q.35 0 .625.225t.325.575l.65 3.5q.05.35-.012.637q-.063.288-.288.513L7 10.9q1.05 1.8 2.625 3.375T13.1 17l2.35-2.35q.225-.225.588-.338q.362-.112.712-.062l3.45.7q.35.075.575.337q.225.263.225.613v4.05q0 .45-.3.75t-.75.3Z"
    />
  </svg>

  <!-- send -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'send'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M4.4 19.425q-.5.2-.95-.088Q3 19.05 3 18.5v-3.725q0-.35.2-.625t.55-.35L11 12l-7.25-1.8q-.35-.075-.55-.35q-.2-.275-.2-.625V5.5q0-.55.45-.838q.45-.287.95-.087l15.4 6.5q.625.275.625.925t-.625.925Z"
    />
  </svg>

  <!-- tooth -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'tooth'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M7 2C4 2 2 5 2 8c0 2.11 1 5 2 6s2 8 4 8c4.54 0 2-7 4-7s-.54 7 4 7c2 0 3-7 4-8s2-3.89 2-6c0-3-2-6-5-6s-3 1-5 1s-2-1-5-1Z"
    />
  </svg>

  <!-- tooth-stroke -->
  <svg
    class="cmp-icon"
    v-else-if="icon == 'tooth-stroke'"
    xmlns="http://www.w3.org/2000/svg"
    :width="calculatedSize + 'rem'"
    :height="calculatedSize + 'rem'"
    viewBox="0 0 24 24"
  >
    <path
      class="svg-reference"
      :fill="color"
      d="M7 2C4 2 2 5 2 8c0 2.11 1 5 2 6s2 8 4 8c4.54 0 2-7 4-7s-.54 7 4 7c2 0 3-7 4-8s2-3.89 2-6c0-3-2-6-5-6s-3 1-5 1s-2-1-5-1m0 2c2 0 3 1 5 1s3-1 5-1c1.67 0 3 2 3 4c0 1.75-.86 4.11-1.81 5.06c-.86.86-2.13 6.88-2.69 6.88c-.21 0-.5-1.06-.5-2.35c0-2.04-.57-4.59-3-4.59s-3 2.55-3 4.59c0 1.29-.29 2.35-.5 2.35c-.56 0-1.83-6.02-2.69-6.88C4.86 12.11 4 9.75 4 8c0-2 1.33-4 3-4Z"
    />
  </svg>
</template>

<script>
export default {
  props: ["color", "size", "icon"],
  setup(props) {
    let fontSize = 16;
    let calculatedSize = 1;
    if (props.size) {
      calculatedSize = props.size / fontSize;
    }

    return { calculatedSize };
  },
};
</script>