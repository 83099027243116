<template>
  <div v-if="showPrices">
    <div class="search-services">
      <label for="search-field">Caută servicii: </label>
      <input id="search-field" type="text" v-model="search" placeholder="nume serviciu..." />
    </div>
    <PricesCategory v-for="(category, i) in filteredCategories" :category="category" :key="i" />
  </div>
</template>

<script>
import PricesCategory from "./PricesCategory.vue";

import { ref, computed } from "@vue/reactivity";
import { onMounted } from 'vue';

export default {
  components: { PricesCategory },
  setup() {
    const categories = ref([]);
    const showPrices = ref(false);
    const search = ref('');

    const sheetId = '1W-V9WOpwyX9RWH8JV2t47D3GlQGSXbrjKHexm-1Nn7w';
    const base = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?`;
    const sheetName = 'tarife';
    const query = encodeURIComponent('Select *')
    const url = `${base}&sheet=${sheetName}&tq=${query}`

    onMounted(async () => {
      const data = [];
      const foundCat = [];
      let allCat = [];

      fetch(url)
        .then(res => res.text())
        .then(rep => {
          const jsonData = JSON.parse(rep.substring(47).slice(0, -2));
          jsonData.table.rows.forEach((rowData) => {
            if (!foundCat.includes(rowData.c[1].v.toLowerCase())) {
              foundCat.push(rowData.c[1].v.toLowerCase());
            }
            const service = {
              id: rowData.c[0].v + '.' + rowData.c[2].v,
              category: rowData.c[1].v.toLowerCase(),
              name: rowData.c[3]?.v || '',
              price: rowData.c[4]?.v || '',
              currency: rowData.c[5]?.v || '',
            };
            data.push(service);
          })
        })
        .then(rep => {
          allCat = foundCat.map((cat) => {
            const services = data.filter((serv) => serv.category === cat);
            return {
              name: cat,
              services,
            };
          })
          categories.value = allCat;
          showPrices.value = true;
        })
    })

    const normalizeDiacritics = (str) => {
      const map = {
        'ă': 'a',
        'â': 'a',
        'î': 'i',
        'ș': 's',
        'ț': 't',
      };
      return str.toLowerCase().replace(/[ăâîșț]/g, (match) => map[match]);
    };

    const filteredCategories = computed(() => {
      if (!search.value) {
        return categories.value;
      }
      const normalizedSearch = normalizeDiacritics(search.value);
      return categories.value.map(category => {
        const filteredServices = category.services.filter(service =>
          normalizeDiacritics(service.name).includes(normalizedSearch)
        );
        return { ...category, services: filteredServices };
      }).filter(category => category.services.length > 0);
    });

    return { categories, showPrices, search, filteredCategories };
  },
};
</script>