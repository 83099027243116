<template>
  <div class="container-white-background">
    <HeaderSideBySide :header="service.serviceHeader" />
    <div class="service container">
      <ServiceComponent
        v-for="(comp, index) in service.pageComponents"
        :key="index"
        :component="comp"
        :after="service.defaultMargin"
      />
    </div>
    <MakeAnAppointment />
    <Separator size="50" />
  </div>
</template>

<script>
import { useServicesStore } from "@/stores/ServicesStore";
import { useRoute } from "vue-router";

import Separator from "@/components/Separator.vue";
import ServiceComponent from "./ServiceComponent.vue";
import HeaderSideBySide from "@/components/subcomponents/HeaderSideBySide.vue";
import MakeAnAppointment from "@/components/MakeAnAppointment.vue";

export default {
  components: {
    HeaderSideBySide,
    Separator,
    ServiceComponent,
    MakeAnAppointment
  },
  setup() {
    let services = useServicesStore().services;
    let service = services.find((srv) => srv.link == useRoute().params.id);

    return { service };
  },
};
</script>