<template>
  <div class="cmp-list">
    <div class="cmp-list-content cmp-title">
      <h3 v-if="list.title && list.title.length > 0">{{ list.title }}</h3>
      <p v-if="list.text && list.text.length > 0">{{ list.text }}</p>
      <ol v-if="list.isOrdered" class="list-in-teaser">
        <li class="inside-cmp-list-li" v-for="(item, index) in list.listItems" :key="index">{{ item }}</li>
      </ol>
      <ul v-else class="list-in-teaser">
        <li class="inside-cmp-list-li" v-for="(item, index) in list.listItems" :key="index">{{ item }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["list"],
};
</script>