<template>
  <div class="cmp-teaser-ittb">
    <Icon :size="25" color="red" icon="contact" />
    <h3>{{ teaser.title }}</h3>
    <p class="inside-cmp-text" v-for="(par, index) in teaser.paragraphs" :key="index">{{ par }}</p>
    <Button />
  </div>
</template>

<script>
import Button from "./Button.vue";
import Icon from "../Icon.vue";

export default {
  components: { Button, Icon },
  props: ["teaser"],
};
</script>