<template>
  <div class="cmp-header-sbs" :class="[header.imageLeft ? 'imageLeft' : '']">
    <div class="cmp-header-sbs__image">
      <img
        :src="header.image.src"
        :alt="header.image.alt"
        class="cmp-header-sbs__image-image"
      />
    </div>
    <TitleTextParagraphs class="container" :comp="titleText" />
  </div>
</template>

<script>
import TitleTextParagraphs from "@/components/subcomponents/TitleTextParagraphs.vue";

export default {
  components: { TitleTextParagraphs },
  props: ["header"],
  setup(props) {
    const titleText = {
      title: props.header.title,
      titleType: props.header.titleType,
      paragraphs: props.header.paragraphs,
    };

    return { titleText };
  },
};
</script>