import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyAUJxp1V3lQqjWx9pLfTkKrtEv4cUnKjZA",
  authDomain: "visodent-stage.firebaseapp.com",
  projectId: "visodent-stage",
  storageBucket: "visodent-stage.appspot.com",
  messagingSenderId: "147904888528",
  appId: "1:147904888528:web:0b05eac0d455562fb568d5"
};

firebase.initializeApp(config);

const db = firebase.firestore();
const auth = firebase.auth();
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { db, auth, timestamp };
