<template>
  <div v-if="!appointmentDone" class="appointment">
    <div class="form-field">
      <label for="lastName" class="required-field">Nume</label>
      <input
        maxlength="100"
        class="form-input-field"
        type="text"
        id="lastName"
        :disabled="blockedInput == 1"
        v-model="lastName"
      />
    </div>
    <div class="form-field">
      <label for="firstName" class="required-field">Prenume</label>
      <input
        maxlength="100"
        class="form-input-field"
        type="text"
        id="firstName"
        :disabled="blockedInput == 1"
        v-model="firstName"
      />
    </div>
    <div class="form-field">
      <label for="phone" class="required-field">Telefon</label>
      <input
        maxlength="20"
        class="form-input-field"
        type="text"
        id="phone"
        :disabled="blockedInput == 1"
        v-model="phone"
      />
    </div>
    <div class="form-field">
      <label for="email">Email</label>
      <input
        maxlength="100"
        class="form-input-field"
        type="email"
        id="email"
        :disabled="blockedInput == 1"
        v-model="email"
      />
    </div>
    <div class="form-field">
      <label for="message" class="required-field">Mesaj</label>
      <textarea
        maxlength="1000"
        class="form-input-field"
        type="text"
        id="message"
        :disabled="blockedInput == 1"
        v-model="message"
      />
    </div>
    <div class="form-field">
      <label for="suggestion">Preferințe de timp</label>
      <textarea
        maxlength="1000"
        class="form-input-field"
        type="text"
        id="suggestion"
        :disabled="blockedInput == 1"
        v-model="suggestion"
      />
    </div>
    <div class="form-field form-field-checkbox" id="emergency-field">
      <input
      class="form-input-field"
      type="checkbox"
      id="emergency"
      :disabled="blockedInput == 1"
      v-model="emergency"
      />
      <label for="emergency">Urgență</label>
    </div>
    <div class="form-field form-field-checkbox" id="emergency-field">
      <input
      class="form-input-field"
      type="checkbox"
      id="gdpr"
      :disabled="blockedInput == 1"
      v-model="gdpr"
      />
      <label for="emergency" class="required-field"
        >Declar ca am citit si sunt de acord cu
        <router-link :to="{ name: 'GDPR' }" target="_blank"
          >Termenele si conditiile</router-link
        >
        de utilizare ale site-ului</label
      >
    </div>
    <div class="errorMessage" v-if="errorMessage">
      <TextSimple :comp="{ text: errorMessage }" />
    </div>
    <button
      :class="['button fill', !gdpr ? 'disabled' : '']"
      v-on:click="sendAppointment"
      :disabled="!gdpr"
    >
      Trimite
    </button>
  </div>
  <div v-else>
    <TextSimple :comp="{ text: 'Programare realizata cu succes' }" />
  </div>
</template>

<script>
import { timestamp } from "../firebase/config";
import { ref } from "vue";
import useCollection from "../static/composables/useCollection";
import TextSimple from "./subcomponents/TextSimple.vue";

export default {
  components: { TextSimple },
  setup() {
    const appointmentDone = ref(false);
    let blockedInput = ref(0);
    const displayError = ref(false);
    const email = ref("");
    const emergency = ref(false);
    const gdpr = ref(false);
    const errorMessage = ref("");
    const firstName = ref("");
    const lastName = ref("");
    const message = ref("");
    const phone = ref("");
    const suggestion = ref("");

    const { addDoc, error } = useCollection("requestedAppointments");

    const sendAppointment = async () => {
      errorMessage.value = null;
      if (
        (email.value.length < 6,
        firstName.value.length < 2,
        lastName.value.length < 2,
        phone.value.length < 8,
        message.value.length < 5)
      ) {
        errorMessage.value = "Vă rugăm să completați toate câmpurile necesare!";
        return;
      }
      blockedInput.value = 1;
      console.log("Send appointment");
      const newAppointment = {
        approvedAt: null,
        createdAt: timestamp(),
        createdBy: "user",
        doctorApproved: false,
        email: email.value,
        emergency: emergency.value,
        firstName: firstName.value,
        lastName: lastName.value,
        message: message.value,
        phone: phone.value,
        suggestion: suggestion.value,
      };

      await addDoc(newAppointment);

      if (error.value) {
        if (error.value == "404") {
          errorMessage.value =
            "Ceva nu a mers bine, vă rugăm să reîncercați. Altfel, ne puteți contacta telefonic.";
          displayError.value = true;
          blockedInput.value = 0;
        }
      } else {
        displayError.value = false;
        email.value = "";
        emergency.value = false;
        errorMessage.value = "";
        firstName.value = "";
        lastName.value = "";
        message.value = "";
        phone.value = "";
        suggestion.value = "";
        appointmentDone.value = true;
        blockedInput.value = 0;
      }
    };

    return {
      appointmentDone,
      blockedInput,
      email,
      emergency,
      gdpr,
      errorMessage,
      firstName,
      lastName,
      message,
      phone,
      sendAppointment,
      suggestion,
    };
  },
};
</script>