import { ref } from "vue";
import { auth } from "../../firebase/config";

const error = ref(null);

const signup = async (lastName, firstName, phone, email, password) => {
  error.value = null;

  try {
    const res = await auth.createUserWithEmailAndPassword(email,password);
    if (!res) {
      throw new Error("Înregistrarea nu a putut fi efectuată cu succes.");
    }
    console.log(res.user);
  } catch (err) {
    console.log(err.message);
    error.value = err.message;
  }
};

const useSignup = () => {
  return { error, signup };
};

export default useSignup;
