<template>
  <div
    class="cmp-teaser"
    :class="[
      teaser.isLeft ? 'left' : 'right',
      teaser.isSquare ? 'square' : teaser.isPortrait ? 'portrait' : 'landscape',
      teaser.isHorizontalFlip ? 'horizontal-flip' : '',
      teaser.isVerticalFlip ? 'vertical-flip' : '',
    ]"
  >
    <div class="cmp-teaser__image">
      <img v-if="teaser.image" :src="teaser.image" :alt="teaser.alt" />
    </div>
    <div class="cmp-teaser-content cmp-title">
      <h3 v-if="teaser.title && teaser.title.length > 0">{{ teaser.title }}</h3>
      <p class="inside-cmp-text" v-for="(par, index) in teaser.paragraphs" :key="index">{{ par }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["teaser"],
};
</script>