import { defineStore } from "pinia";

export const useServicesStore = defineStore("servicesStore", {
  state: () => ({
    services: [
      {
        img: "https://images.unsplash.com/photo-1593022356769-11f762e25ed9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
        title: "Consultație ",
        link: "consultatie",
        text: "Pasul inițial către zâmbetul ideal: diagnostic precis, tratament adaptat",
        defaultMargin: 50,
        serviceHeader: {
          title: "Consultație",
          titleType: 1,
          paragraphs: [""],
          image: {
            alt: "alt text",
            src: "https://images.unsplash.com/photo-1617812191081-2a24e3f30e45?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80",
          },
        },
        pageComponents: [
          {
            component: "classic-teaser",
            paragraphs: [
              "La consultațiile noastre nu trebuie să-ți fie teamă, deoarece facem o evaluare completă a sănătății tale orale, fără durere. Dentistul nostru va face o examinare detaliată, ajutându-se de o radiografie. Vom discuta cu tine despre istoricul tău medical și dentar pentru a ne asigura că primești cel mai bun tratament.",
            ],
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F01-02.jpg?alt=media&token=887392f7-7798-44d4-913c-80dd7107907b",
            alt: "alt text for image",
            isLeft: true,
            isSquare: true,
          },
          {
            component: "classic-teaser-with-list",
            title: "Importanța unei consultații la timp:",
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F01-04.jpg?alt=media&token=3ec4594f-5928-421c-b556-d3c6ffbf2d75",
            alt: "imagine",
            listItems: [
              "Prevenția este cheia: Venind la timp pentru consultații, poți preveni problemele dentare dureroase și costisitoare. Este foarte important să vii la dentist la fiecare 6 luni pentru a-ți menține sănătatea dentară.",
              "Fără temeri: Nu trebuie să te temi de controlul de rutină. Este o procedură simplă și rapidă, care te ajută să te asiguri că totul este în regulă cu dinții tăi.",
              "Detecție timpurie: Problemele dentare, cum ar fi cariile și bolile gingivale, pot fi detectate și tratate din timp, înainte de a deveni dureroase sau complicate.",
            ],
            isPortrait: true,
          },
          {
            component: "classic-teaser-with-list",
            title: "Beneficii:",
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F01-03.jpg?alt=media&token=b224721f-2f4c-4954-91c0-da4ba3fbb613",
            alt: "imagine",
            listItems: [
              "Identificarea rapidă a problemelor dentare.",
              "Planificarea unui tratament personalizat și preventiv.",
              "Menținerea sănătății generale a gurii tale.",
            ],
            isLeft: true,
          },
          {
            component: "list",
            title: "Durata:",
            listItems: ["Aproximativ 30 de minute."],
          },
        ],
      },
      {
        img: "https://images.unsplash.com/photo-1593022356769-11f762e25ed9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
        title: "Prevenție",
        link: "preventie",
        text: "Curățare profundă prin air flow și detartraj pentru o dantură strălucitoare.",
        defaultMargin: 50,
        serviceHeader: {
          title: "Prevenție",
          titleType: 1,
          paragraphs: [""],
          image: {
            alt: "alt text",
            src: "https://images.unsplash.com/photo-1617812191081-2a24e3f30e45?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80",
          },
        },
        pageComponents: [
          {
            component: "classic-teaser",
            paragraphs: [
              "Îngrijirea preventivă și igiena orală sunt esențiale pentru a menține sănătatea dentară pe termen lung. La noi, profilaxia dentară profesională include detartrajul, periajul profesional și controlul stomatologic periodic, efectuate de două ori pe an.",
            ],
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F02-01.png?alt=media&token=ab390ee0-bfb3-47f5-a1ea-515f0e9a8aaf",
            alt: "alt text for image",
            title: "Prevenție și igienă orală",
            titleType: 3,
            isSquare: true,
            isLeft: true,
            isHorizontalFlip: true,
          },
          {
            component: "title-text-simple",
            text: "Detartraj și periaj profesional: Periajul dentar zilnic este extrem de important, dar chiar și atunci când este efectuat corect și regulat, nu poate preveni complet depunerile de tartru. Așadar, este necesară intervenția periodică de îndepărtare profesională a tartrului prin ședințele profesionale de profilaxie.",
            title: "Profilaxia dentară profesională:",
            titleType: 3,
          },
          {
            component: "classic-teaser",
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F02-03.jpg?alt=media&token=70d91832-7399-4442-90fa-7a797e68273e",
            alt: "alt text for image",
            paragraphs: [
              "Controalele regulate la medicul dentist ajută la descoperirea din faza incipientă a oricărei noi afecțiuni și la menținerea sănătății dentare pe termen lung.",
              "O bună igienă orală este importantă atât din punct de vedere estetic, cât și pentru sănătatea generală. O igienă dentară necorespunzătoare poate duce la probleme medicale complexe precum afecțiuni gingivale și parodontale, pierderi osoase, afecțiuni cardiace, accidente vasculare, naștere prematură și progresia diabetului.",
            ],
            title: "Importanța prevenției:",
            titleType: 3,
          },
          {
            component: "classic-teaser-with-list",
            title: "Beneficii ale igienizării:",
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F02-02.jpg?alt=media&token=8655432d-2cf7-4054-9f01-d642cf1c1f24",
            alt: "imagine",
            listItems: [
              "Curățare completă și eficientă a danturii.",
              "Prevenirea bolilor gingivale și parodontale prin îndepărtarea plăcii bacteriene și a tartrului.",
              "Menținerea sănătății orale și obținerea unei respirații proaspete.",
              "Identificarea altor probleme dentare care necesită tratament urgent.",
              "Prelungirea vieții lucrărilor dentare realizate în cabinetul stomatologic.",
            ],
            isLeft: true,
          },
        ],
      },
      {
        img: "https://images.unsplash.com/photo-1593022356769-11f762e25ed9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
        title: "Estetică dentară ",
        link: "estetica",
        text: "Ne ocupăm de frumusețea danturii tale.",
        defaultMargin: 50,
        serviceHeader: {
          title: "Estetică dentară",
          titleType: 1,
          paragraphs: [""],
          image: {
            alt: "alt text",
            src: "https://images.unsplash.com/photo-1617812191081-2a24e3f30e45?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80",
          },
        },
        pageComponents: [
          {
            component: "classic-teaser",
            title: "Estetica dentară",
            paragraphs: [
              "se concentrează pe îmbunătățirea aspectului zâmbetului tău. La VisoDent, ne propunem să rezolvăm problemele pacienților noștri legate de frumusețea și sănătatea danturii.",
            ],
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F03-03.jpg?alt=media&token=18b68b33-08f8-4b3c-9152-d1f54d3eca43",
            alt: "Imagine cu luarea culorii dinților",
            isLeft: true,
          },
          {
            component: "classic-teaser-with-list",
            title: "Importanța esteticii dentare:",
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F03-01.jpg?alt=media&token=8e8de103-6af4-4a4e-8124-1a9cb6d72f3d",
            alt: "Imagine cu gutieră",
            listItems: [
              "Încrederea în sine: Un zâmbet frumos îți poate crește considerabil încrederea în sine.",
              "Prima impresie: Un zâmbet sănătos și atractiv lasă o impresie pozitivă în interacțiunile sociale și profesionale.",
              "Arii diverse ale medicinei dentare: Estetica dentară înglobează mai multe arii ale medicinei dentare pentru a asigura un rezultat final care să corespundă așteptărilor fiecărui pacient.",
            ],
          },
          {
            component: "classic-teaser-with-list",
            title: "Beneficii:",
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F03-04.jpg?alt=media&token=4d509410-56d7-433e-a67a-197cd58644d2",
            alt: "Imagine cu gutieră",
            listItems: [
              "Dinți mai albi și mai estetici.",
              "Corectarea imperfecțiunilor dentare.",
              "Îmbunătățirea formei și alinierii dinților.",
              "Creșterea încrederii în sine.",
            ],
            isLeft: true,
          },
          {
            component: "list",
            title: "Durata:",
            listItems: [
              "Variază în funcție de tratament (de la 60 de minute pentru albire la 2-3 ședințe pentru fațete).",
            ],
          },
        ],
      },

      {
        img: "https://images.unsplash.com/photo-1593022356769-11f762e25ed9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
        title: "Parodontologie ",
        link: "parodontologie",
        text: "Combatem bolile parodontale prin igienizări profesionale.",
        defaultMargin: 50,
        serviceHeader: {
          title: "Parodontologie",
          titleType: 1,
          paragraphs: [""],
          image: {
            alt: "alt text",
            src: "https://images.unsplash.com/photo-1617812191081-2a24e3f30e45?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80",
          },
        },
        pageComponents: [
          {
            component: "classic-teaser",
            paragraphs: [
              "Parodontologia se ocupă cu prevenirea, diagnosticarea și tratarea bolilor gingivale și ale țesuturilor de susținere a dinților. Problemele gingivale pot duce la pierderea dinților dacă nu sunt tratate la timp.",
            ],
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F04-01.jpg?alt=media&token=5445e5a6-0353-4d32-a145-945ac82c9d40",
            alt: "pacient cu gura deschisă",
            isLeft: true,
          },
          {
            component: "title-text-simple",
            text: "Conform Organizației Mondiale a Sănătății, peste 20% dintre adulții cu vârsta între 35 și 44 de ani suferă de o afecțiune parodontală, aceasta fiind și una dintre cauzele principale ale pierderii premature a unuia sau mai multor dinți.",
            title: "Importanța sănătății parodontale",
            titleType: 3,
          },
          {
            component: "title-text-simple",
            text: "Una dintre cele mai răspândite afecțiuni dentare la nivel global, se manifestă prin inflamarea și înroșirea gingiilor, iar în stadii avansate prin sângerare la periaj, retracție gingivală, durere la masticație și mobilitate dentară, ducând în final la pierderea dinților.",
            title: "Parodontoza",
            titleType: 3,
          },
          {
            component: "classic-teaser-with-list",
            title: "Servicii incluse:",
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F04-03.jpg?alt=media&token=dc41b79b-0abb-4305-bdf6-bf6a3071870a",
            alt: "Imagine cu gutieră",
            listItems: [
              "Tratamentul gingivitei: tratamente pentru inflamația gingiilor cauzată de placa bacteriană.",
              "Tratamentul parodontitei: proceduri pentru tratarea infecțiilor severe ale gingiilor și țesutului osos care susține dinții.",
              "Chirurgie parodontală: Intervenții chirurgicale minore pentru regenerarea țesutului gingival și osos.",
            ],
          },
          {
            component: "classic-teaser-with-list",
            title: "Beneficii tratament:",
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F04-02.jpg?alt=media&token=c5fc29d5-e51c-408d-b5d2-1eb4af8d046f",
            alt: "Imagine cu gutieră",
            listItems: [
              "Menținerea și restaurarea sănătății gingivale.",
              "Prevenirea pierderii dinților.",
              "Îmbunătățirea sănătății generale.",
              "Creșterea confortului și calității vieții.",
            ],
            isLeft: true,
            isPortrait: true,
          },
          {
            component: "list",
            title: "Durata:",
            listItems: [
              "Variază în funcție de tratament și de severitatea afecțiunii.",
            ],
          },
        ],
      },
      {
        img: "https://images.unsplash.com/photo-1593022356769-11f762e25ed9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
        title: "Protetica dentară ",
        link: "protetica",
        text: "Fațete dentare și coroane calitative pentru estetica dorită.",
        defaultMargin: 50,
        serviceHeader: {
          title: "Protetică dentară",
          titleType: 1,
          paragraphs: [""],
          image: {
            alt: "alt text",
            src: "https://images.unsplash.com/photo-1617812191081-2a24e3f30e45?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80",
          },
        },
        pageComponents: [
          {
            component: "classic-teaser",
            paragraphs: [
              "Protetica dentară se ocupă cu restaurarea și înlocuirea dinților lipsă sau deteriorați, utilizând diverse tipuri de coroane, punți și proteze dentare. Aceste tratamente îmbunătățesc funcționalitatea și estetica danturii, asigurând o masticație corectă și un zâmbet atrăgător.",
            ],
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F05-03.jpg?alt=media&token=5af951db-2a1c-4c59-978e-ab997d0d0f0a",
            alt: "Imagine cu o amprentă dentară",
            isLeft: true,
            isSquare: true,
          },
          {
            component: "classic-teaser",
            paragraphs: [
              "Atunci când ai nevoie de o proteză dentară, prețul nu ar trebui să fie singurul criteriu după care să alegi soluția medicală potrivită. Materialele folosite, experiența specialistului protetician, sistemul protetic și confortul și stabilitatea în timpul masticației sunt de o importanță majoră. De regulă, lucrările protetice de calitate superioară au un preț mai ridicat, însă unul dintre obiectivele noastre este să îți oferim lucrări dentare ireproșabile la cel mai bun raport calitate-preț.",
            ],
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F05-01.jpg?alt=media&token=6bf694f5-fedc-4edb-9d62-83d71c1d2896",
            alt: "Imagine cu instrumente profesionale",
            isSquare: true,
          },
          {
            component: "classic-teaser-with-list",
            title: "Importanța proteticii dentare:",
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F05-02.jpg?alt=media&token=f673560b-e65a-4686-9bd8-40a4d7b7cf6b",
            alt: "Imagine cu gutieră",
            listItems: [
              "Restaurarea funcționalității: Protetica dentară permite restabilirea capacității de a mesteca și vorbi corect.",
              "Îmbunătățirea esteticii: Soluțiile protetice ajută la refacerea unui zâmbet natural și atrăgător.",
              "Prevenirea problemelor viitoare: Restaurarea dinților lipsă previne deplasarea dinților rămași și alte complicații.",
            ],
            isLeft: true,
          },
          {
            component: "classic-teaser-with-list",
            title: "Beneficii:",
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F05-04.jpg?alt=media&token=51a39e27-7b5a-4c1a-94e2-d780c5acf1b4",
            alt: "Imagine cu gutieră",
            listItems: [
              "Restaurarea și îmbunătățirea funcționalității dentare.",
              "Îmbunătățirea esteticii zâmbetului.",
              "Creșterea confortului și a calității vieții.",
              "Prevenirea problemelor dentare suplimentare.",
            ],
          },
          {
            component: "list",
            title: "Durata:",
            listItems: [
              "Variază în funcție de tratament și de complexitatea cazului (de la 2-3 ședințe pentru coroane la mai multe ședințe pentru proteze și punți).",
            ],
          },
        ],
      },
      {
        img: "https://images.unsplash.com/photo-1593022356769-11f762e25ed9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
        title: "Reabilitare orală",
        link: "reabilitare",
        text: "Tratamente odontale, edodontice, chirurgicale și parodontale pentru un zâmbet perfect.",
        defaultMargin: 50,
        serviceHeader: {
          title: "Reabilitare orală",
          titleType: 1,
          paragraphs: [""],
          image: {
            alt: "alt text",
            src: "https://images.unsplash.com/photo-1617812191081-2a24e3f30e45?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80",
          },
        },
        pageComponents: [
          {
            component: "classic-teaser",
            paragraphs: [
              "Reabilitarea orală totală este un proces complex de restaurare completă a funcționalității și esteticului cavității orale. Acest tratament combină diverse proceduri dentare pentru a rezolva problemele majore de sănătate orală și pentru a reda un zâmbet sănătos și atrăgător.",
            ],
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F06-01.jpg?alt=media&token=dd8952ce-ee50-4151-8c10-dbaadd272219",
            alt: "Imagine cu o amprentă dentară",
            isLeft: true,
          },
          {
            component: "classic-teaser-with-list",
            title: "Servicii incluse:",
            image:
            "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F06-02.jpg?alt=media&token=cc27aea8-377a-49cb-9268-e431080afd70",
            alt: "Imagine cu gutieră",
            listItems: [
              "Evaluare completă: O evaluare detaliată a stării actuale a dinților, gingiilor și structurilor de susținere pentru a stabili un plan de tratament personalizat.",
              "Implanturi dentare: Înlocuirea dinților lipsă cu implanturi dentare integrate în osul maxilar.",
            ],
          },
          {
            component: "list",
            listItems: [
              "Tratamente protetice: Utilizăm un scaner intraoral performant pentru a crea coroane, punți și proteze dentare de înaltă precizie. Datorită tehnologiei de ultimă generație, mai multe informații la o rezoluție foarte înaltă sunt captate și procesate rapid cu o precizie remarcabilă, permițând astfel efectuarea restaurărilor de la cele unidentare la reabilitări totale. Scanerul Primescan stabilește noi standarde în tehnologia de scanare, făcând scanarea intraorală mai precisă, rapidă și ușoară decât oricând. Este ușor de manevrat și imediat disponibil pentru utilizare, asigurând un proces de lucru mult mai confortabil și de scurtă durată pentru pacienții noștri.",
              "Tratamente chirurgicale: Utilizarea laserului Berylas cu diode în tratamentele stomatologice oferă numeroase avantaje, cum ar fi reducerea disconfortului și a durerii în timpul și după proceduri, accelerarea vindecării țesuturilor, și minimizarea sângerărilor. Tratamentul este rapid și mai puțin invaziv, ceea ce înseamnă o experiență mai confortabilă pentru pacienți și o recuperare mai rapidă. De asemenea, riscul de infecții este redus datorită capacității laserului de a dezinfecta zona tratată.",
            ],
          },
          {
            component: "classic-teaser-with-list",
            title: "Durata:",
            isLeft: true,
            image: "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F06-03.jpg?alt=media&token=06e35d0e-0bdf-4ecf-a24a-c21b929ab9d3",
            alt: "Imagine cu o proteză dentară",
            listItems: [
              "Variază în funcție de complexitatea cazului și de numărul de proceduri necesare (poate dura câteva luni).",
            ],
          },
        ],
      },
      {
        img: "https://images.unsplash.com/photo-1593022356769-11f762e25ed9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
        title: "Ortodonție ",
        link: "ortodontie",
        text: "Înfrumusețarea zâmbetului cu ajutorul aparatelor dentare avansate.",
        defaultMargin: 50,
        serviceHeader: {
          title: "Ortodonție",
          titleType: 1,
          paragraphs: [""],
          image: {
            alt: "alt text",
            src: "https://images.unsplash.com/photo-1617812191081-2a24e3f30e45?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80",
          },
        },
        pageComponents: [
          {
            component: "classic-teaser",
            title: "Ortodonția",
            paragraphs: [
              "este o specialitate a stomatologiei moderne care se concentrează pe prevenirea, diagnosticarea și corectarea malocluziilor dentare și a anomaliilor maxilo-faciale, utilizând aparate ortodontice.",
            ],
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F07-01.jpg?alt=media&token=e788766c-41ef-4c3a-afc7-4c951ad622ac",
            alt: "Imagine cu luarea culorii dinților",
            isLeft: true,
            isPortrait: false,
            isSquare: false,
            isHorizontalFlip: false,
            isVerticalFlip: false,
          },
          {
            component: "classic-teaser",
            paragraphs: [
              "Ocluzia corectă este realizată atunci când arcada maxilară se suprapune perfect pe arcada mandibulară, asigurând un contact optim între dinți. Dinții de sus protejează obrajii și buzele în timpul masticației, iar cei de jos protejează limba, prevenind astfel mușcarea accidentală a acestor țesuturi.",
              "O ocluzie incorectă poate duce la un contact deficitar între dinți, cauzând uzură excesivă, carii sau fracturi dentare.",
            ],
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F07-02.jpg?alt=media&token=9a666226-be1a-4607-ad84-f68fa3f5df3f",
            alt: "Imagine cu luarea culorii dinților",
            isLeft: false,
            isPortrait: false,
            isSquare: false,
            isHorizontalFlip: false,
            isVerticalFlip: false,
          },
          {
            component: "classic-teaser-with-list",
            title: "Servicii incluse",
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F07-03.jpg?alt=media&token=6a245b2e-5e8b-4dd7-a74d-d867953701d2",
            alt: "Medic stomatolog în timpul unei consultații",
            listItems: [
              "Evaluare detaliată a alinierii dinților și a ocluziei pentru a planifica tratamentul personalizat.",
              "Utilizarea aparatelor dentare metalice sau ceramice pentru corectarea poziției dinților.",
              "Dispozitive detașabile pentru corectarea poziției dentare la copii și adolescenți.",
              "Aliniatori transparenți, personalizați, pentru corectarea dinților nealiniați.",
              "Menținerea aliniamentului dentar corect după tratamentul ortodontic.",
            ],
            isLeft: true,
            isPortrait: false,
            isSquare: false,
            isHorizontalFlip: false,
            isVerticalFlip: false,
          },
          {
            component: "classic-teaser-with-list",
            title: "Beneficiile tratamentelor ortodontice:",
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F07-04.jpg?alt=media&token=90f8e488-b274-4cb9-8262-0c3971d699b8",
            alt: "Pacient în timpul tratamentului ortodontic",
            listItems: [
              "Corectarea ocluziei: Tratamentul ortodontic corectează malocluziile, asigurând un contact optim între dinți și prevenind uzura și fracturile dentare.",
              "Îmbunătățirea funcționalității: O ocluzie corectă îmbunătățește funcția masticatorie și fonetică.",
              "Sănătatea orală: Dinții aliniați corect sunt mai ușor de igienizat, reducând riscul de carii și boli parodontale.",
              "Estetică: Un zâmbet armonios și aliniat îmbunătățește aspectul facial și crește încrederea în sine.",
            ],
            isLeft: false,
            isPortrait: false,
            isSquare: false,
            isHorizontalFlip: false,
            isVerticalFlip: false,
          },
          {
            component: "list",
            title: "Durata:",
            listItems: [
              "Variază în funcție de tratament și de complexitatea cazului",
            ],
          },
        ],
      },
      {
        img: "https://images.unsplash.com/photo-1593022356769-11f762e25ed9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
        title: "Pedodonție",
        link: "pedodontie",
        text: "Protejăm zâmbetele celor mici prin periaje delicate și fluorizări cu gust plăcut.",
        defaultMargin: 50,
        serviceHeader: {
          title: "Pedodonție",
          titleType: 1,
          paragraphs: [""],
          image: {
            alt: "alt text",
            src: "https://images.unsplash.com/photo-1617812191081-2a24e3f30e45?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80",
          },
        },
        pageComponents: [
          {
            component: "classic-teaser",
            title: "Pedodonția",
            paragraphs: [
              "este ramura stomatologiei care se ocupă de prevenția, diagnosticarea și tratamentul afecțiunilor dentare la copii. Acest serviciu include îngrijirea dentară completă pentru copii, de la erupția primilor dinți până la adolescență, asigurând o dezvoltare sănătoasă a danturii și obiceiuri de igienă orală corecte.",
            ],
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F08-03.jpg?alt=media&token=c22543fd-4d60-4381-bea9-b5804d1fb5e4",
            alt: "Mulaj proteză dentară",
            isLeft: true,
          },
          {
            component: "classic-teaser-with-list",
            title: "Servicii incluse:",
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F08-01.jpg?alt=media&token=2fad40c8-bd79-415e-855c-7d5c9b7bdbb6",
            alt: "Periaj dentar",
            listItems: [
              "Evaluarea stării de sănătate orală a copilului și monitorizarea dezvoltării dentare.",
              "Identificarea și tratarea cariilor dentare prin obturații sau alte proceduri adecvate.",
              "Instruirea copiilor și a părinților cu privire la tehnicile corecte de periaj și utilizarea aței dentare.",
            ],
          },
          {
            component: "classic-teaser-with-list",
            title: "Importanța pedodonției:",
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F08-02.jpg?alt=media&token=8acb6e52-789d-4e44-9e39-bc520c7442d0",
            alt: "Gutieră dentară",
            listItems: [
              "Prevenția este esențială: Îngrijirea dentară timpurie previne problemele majore și contribuie la o sănătate orală bună pe termen lung.",
              "Dezvoltarea sănătoasă: Monitorizarea și tratamentul adecvat asigură o dezvoltare corectă a dinților și a maxilarelor.",
              "Educație și obiceiuri: Educația timpurie în privința igienei orale creează obiceiuri sănătoase care persistă pe tot parcursul vieții.",
            ],
            isLeft: true,
            isPortrait: false,
            isSquare: false,
            isHorizontalFlip: false,
            isVerticalFlip: false,
          },
          {
            component: "classic-teaser-with-list",
            title: "Beneficii:",
            image:
              "https://firebasestorage.googleapis.com/v0/b/visodent-pilecode.appspot.com/o/images%2F08-04.jpg?alt=media&token=98427909-f7de-4b2f-acca-6f735c8c7618",
            alt: "Zâmbet",
            listItems: [
              "Detectarea și prevenirea timpurie a problemelor dentare.",
              "Îmbunătățirea sănătății orale și a dezvoltării dentare.",
              "Educație și formarea de obiceiuri sănătoase de igienă orală.",
              "Tratamente adaptate nevoilor specifice ale copiilor.",
            ],
            isLeft: false,
            isPortrait: false,
            isSquare: false,
            isHorizontalFlip: false,
            isVerticalFlip: false,
          },
          {
            component: "list",
            title: "Durata:",
            listItems: [
              "Variază în funcție de tratament și de necesitățile individuale ale fiecărui copil.",
            ],
          },
        ],
      },
    ],
  }),
});
