<template>
  <div class="cmp-faq__container" :class="[opened ? 'opened' : 'closed']">
    <button class="cmp-faq__item-question" v-on:click="opened = !opened">
      <TextSimple :comp="{text: item.question}"/>
      <Icon :size="30" color="black" icon="arrow-down" />
    </button>
    <div class="cmp-faq__item-answers">
      <div
        class="cmp-faq__item-answers-answer"
        v-for="(ans, i) in item.answers"
        :key="i"
      >
        <TextSimple :comp="{text: ans}"/>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon.vue";
import { ref } from "vue";
import TextSimple from './subcomponents/TextSimple.vue';
export default {
  props: ["item"],
  components: { Icon, TextSimple },
  setup() {
    let opened = ref(false);
    return { opened };
  },
};
</script>