<template>
  <div class="cmp-text-simple">
    <p class="inside-cmp-text">{{ comp.text }}</p>
  </div>
</template>

<script>
export default {
  props: ["comp"],
};
</script>