<template>
  <div class="cmp-service-price-category">
    <div :class="['title-wrapper', { 'opened': showServices }]" @click="showServices = !showServices">
      <Icon :size="40" color="rgb(112, 49, 117)" icon="arrow-down" />
      <Title class="cmp-service-price-category__title" :comp="{ title: category.name, titleType: 3 }" />
      <span>({{ category.services.length }} servicii)</span>
    </div>
    <div v-if="showServices">
      <ServicePrice v-for="(serv, i) in category.services" :service="serv" :key="i" :id="i" />
    </div>
    <Separator :size="20" :line="true" />
  </div>
</template>

<script>
import { ref } from 'vue';
import Separator from "../Separator.vue";
import Title from "../subcomponents/Title.vue";
import ServicePrice from "./ServicePrice.vue";
import Icon from "../Icon.vue";

export default {
  components: { Icon, Separator, ServicePrice, Title },
  props: ["category"],
  setup() {
    const showServices = ref(false);
    return { showServices };
  },
};
</script>