<template>
  <div class="cmp-keys cmp-landing-keys">
    <p>Landing keys</p>
    <!-- <TeaserIconTitleTextButtonVue :teaser=""/> -->
  </div>
</template>

<script>
import TeaserIconTitleTextButtonVue from "./subcomponents/TeaserIconTitleTextButton.vue";

export default {
  components: {
    TeaserIconTitleTextButtonVue,
  },
};
</script>