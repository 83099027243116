<template>
  <PageHead :img="headImg" :title="headTitle" :subtitle="subtitle" />
  <div class="container-white-background">
    <Separator size="75" />
    <div class="appointment-page container container-small">
      <TextSimple :comp="pageParagraph" />
      <Separator size="50" />
      <Appointment />
    </div>
    <Separator size="50" />
  </div>
</template>
  
<script>
import { ref } from "@vue/reactivity";
import Appointment from "../components/Appointment.vue";
import PageHead from "../components/PageHead.vue";
import Separator from "@/components/Separator.vue";
import TextSimple from "@/components/subcomponents/TextSimple.vue";

export default {
  components: { Appointment, PageHead, Separator, TextSimple },
  setup() {
    // #############################
    // Content displayed on the website
    // #############################
    const headImg = ref(
      `https://images.unsplash.com/photo-1435527173128-983b87201f4d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2934&q=80`
    );
    const headTitle = ref(`Solicitați o programare`);
    const subtitle = ref("Intrați în contact cu doctorii stomatologi VisoDent");
    let pageParagraph = {
      text: "Folosind formularul de mai jos, puteți solicita o programare la cabinetul VisoDent din Brăila. Vă rugăm să completați toate câmpurile pentru ca doctorii noștri să vă poată identifica mai ușor sau pentru a putea intra ușor în contact cu dumneavoastră.",
    };
    // #############################
    // #############################

    return { headImg, subtitle, headTitle, pageParagraph };
  },
};
</script>