import { ref } from "vue";
import { auth } from "../../firebase/config";

const error = ref(null);

const login = async (email, password) => {
  error.value = null;

  try {
    const res = await auth.signInWithEmailAndPassword(email, password);
    if (!res) {
      throw new Error("Conectarea nu a putut fi efectuată cu succes.");
    }
    error.value = null;
    console.log(res);
    return res;
  } catch (err) {
    console.log(err.message);
    error.value = "Conectarea nu a putut fi efectuată cu succes.";
  }
};

const useLogin = () => {
  return { error, login };
};

export default useLogin;
