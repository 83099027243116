<template>
  <div class="cmp-service-card" :style="{ 'background-image': 'url(' + service.img + ')' }">
    <div class="text-container">
      <div class="text-container-content">
        <router-link class="like-h3 service-title pretitle" :to="{ name: 'ServiceDetails', params: { id: service.link } }"><h3>{{ service.title }}</h3></router-link>
        <p>{{ service.text }}</p>
        <router-link class="card-link" :to="{ name: 'ServiceDetails', params: { id: service.link } }">Află mai
          multe</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: ["service"],
};
</script>
