import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "../views/LandingPage";
import About from "../views/About";
import Services from "../views/services/Services";
import ServiceDetails from "../views/services/ServiceDetails";
import Prices from "../views/Prices";
import AppointmentPage from "../views/AppointmentPage";
import Contact from "../views/Contact";
import Authentication from "../views/AuthenticationPage";
import Ideas from "../views/IdeasPage";
import GDPR from "../views/Consimtamant";

import NotFound from "../views/NotFound";

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: "/despre-cabinet",
    name: "About",
    component: About,
  },
  {
    path: "/servicii-dentare",
    name: "Services",
    component: Services,
  },
  {
    path: "/servicii-dentare/:id",
    name: "ServiceDetails",
    component: ServiceDetails,
  },
  {
    path: "/tarife",
    name: "Prices",
    component: Prices,
  },
  {
    path: "/programari",
    name: "AppointmentPage",
    component: AppointmentPage,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/ideas",
    name: "Ideas",
    component: Ideas,
  },
  {
    path: "/gdpr",
    name: "GDPR",
    component: GDPR,
  },

  // 404

  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function () {
    window.scrollTo(0, 0);
  },
});

export default router;
