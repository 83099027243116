<template>
  <PageHead :img="headImg" :title="headTitle" :subtitle="subtitle" />
  <div class="container-white-background">
    <Separator size="50" />
    <div class="container">
      <TextSimple :comp="servicesHeader1" />
      <Separator :size="50" :line="true" />
      <TextSimple :comp="servicesHeader2" />
      <Separator size="50" />
      <div class="services">
        <div class="cards" v-if="services.length > 0">
          <Card v-for="(service, index) in services" :key="index" :service="service" />
        </div>
      </div>
    </div>
    <Separator size="50" />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Card from "../../components/Card.vue";
import PageHead from "../../components/PageHead.vue";
import Separator from "@/components/Separator.vue";
import TextSimple from "@/components/subcomponents/TextSimple.vue";

import { useServicesStore } from "../../stores/ServicesStore";

export default {
  components: {
    Card,
    PageHead,
    Separator,
    TextSimple,
  },
  setup() {
    // #############################
    // Content displayed on the website
    // #############################
    const headImg = ref(
      `https://images.unsplash.com/photo-1486049125644-f35e226a5e14?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80`
    );
    const headTitle = ref(`Serviciile dentare oferite`);
    const subtitle = ref("Expertiză stomatologică pentru zâmbete strălucitoare");
    const servicesHeader1 = { text: "Angajamentul nostru este de a vă oferi o îngrijire dentară completă, și cu pasiune ne străduim să vă satisfacem nevoile stomatologice. Pentru a ne atinge țelul, oferim o gamă largă de servicii dentare, de la odontoterapie, ortodonție și chirurgie, până la implantologie și estetică dentară." };
    const servicesHeader2 = { text: "Explorând lista noastră diversificată de servicii, veți găsi fiecare element esențial pentru a vă îngriji sănătatea orală. Fie că doriți o igienizare minuțioasa, o corectare a aliniamentului dentar sau o îmbunătățire estetică, vă suntem alături cu soluții adaptate. Medicii noștri vă vor ajuta și vă vor ghida pentru a lua alegerile potrivite, astfel făcând primii pași către un zâmbet incredibil." };
    // #############################
    // #############################

    const services = useServicesStore().services;

    return { headImg, subtitle, headTitle, services, servicesHeader1, servicesHeader2 };
  },
};
</script>
