<template>
  <div class="landing-page-header">
    <div class="landing-text">
      <h3 class="title">Îngrijirea danturii tale la standarde înalte</h3>
      <h2 class="pretitle">Zâmbește cu încredere</h2>
      <div class="landing-buttons">
        <router-link
        v-if="false"
          class="button appointment-header-button fill"
          to="/programari"
          >Pogramări</router-link
        >
        <router-link class="button appointment-header-button fill" to="/contact">Contact</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingPage",
  props: {},
};
</script>
