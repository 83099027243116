import { defineStore } from "pinia";

export const useOpeningHoursStore = defineStore("openingHours", {
  state: () => ({
    days: [
      {
        day: "Luni",
        dayShort: "Lu",
        hours: "8:00-20:00",
      },
      {
        day: "Marți",
        dayShort: "Ma",
        hours: "8:00-20:00",
      },
      {
        day: "Miercuri",
        dayShort: "Mi",
        hours: "8:00-20:00",
      },
      {
        day: "Joi",
        dayShort: "Jo",
        hours: "8:00-20:00",
      },
      {
        day: "Vineri",
        dayShort: "Vi",
        hours: "8:00-20:00",
      },
      {
        day: "Sâmbătă",
        dayShort: "Sâ",
        hours: "8:00-12:00",
      },
      {
        day: "Duminică",
        dayShort: "Du",
        hours: "închis",
      },
    ],
    periods: [
      {
        label: "Lu-Vi",
        hours: "8:00-20:00"
      },
      {
        label: "Sâ",
        hours: "8:00-12:00"
      },
      {
        label: "Du",
        hours: "închis"
      }
    ]
  }),
});
