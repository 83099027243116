<template>
  <PageHead :img="headImg" :title="headTitle" :subtitle="subtitle" />
  <div class="container-white-background">
    <div class="container policy page">
      <Separator :size="75" :line="false" />
      <TextSimple :comp="paragraph01" />
      <Separator :size="40" :line="false" />
      <TitleTextParagraphs :comp="titleText01" />
      <Separator :size="40" :line="false" />
      <TextSimple :comp="paragraph02" />
      <Separator :size="40" :line="false" />
      <TitleTextParagraphs :comp="titleText02" />
      <Separator :size="40" :line="false" />
      <TitleTextSimple :comp="titleText03" />
      <Separator :size="40" :line="false" />
      <TitleTextSimple :comp="titleText04" />
      <Separator :size="40" :line="false" />
      <TitleTextParagraphs :comp="titleText05" />
      <Separator :size="40" :line="false" />
      <TitleTextParagraphs :comp="titleText06" />
      <Separator :size="40" :line="false" />
      <TitleTextParagraphs :comp="titleText07" />
      <Separator :size="40" :line="false" />
      <Title :comp="title01" />
      <TextSimple :comp="paragraph03" />
      <Separator :size="40" :line="false" />
      <TextSimple :comp="paragraph04" />
      <Separator :size="40" :line="false" />
      <TextSimple :comp="paragraph05" />
      <Separator :size="40" :line="false" />
      <TextSimple :comp="paragraph06" />
      <Separator :size="40" :line="false" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import PageHead from "../components/PageHead.vue";
import Title from "@/components/subcomponents/Title.vue";
import TitleTextSimple from "@/components/subcomponents/TitleTextSimple.vue";
import TitleTextParagraphs from "@/components/subcomponents/TitleTextParagraphs.vue";
import TextSimple from "@/components/subcomponents/TextSimple.vue";
import TextParagraphs from "@/components/subcomponents/TextParagraphs.vue";
import Separator from "@/components/Separator.vue";

export default {
  components: {
    PageHead,
    Separator,
    Title,
    TitleTextSimple,
    TitleTextParagraphs,
    TextSimple,
    TextParagraphs,
  },
  setup() {
    const headImg = ref(
      `https://images.unsplash.com/photo-1457131760772-7017c6180f05?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1153&q=80`
    );
    const headTitle = ref("GDPR");
    const subtitle = ref(
      "Politica de Confidențialitate și Protecția Datelor Personale"
    );

    const paragraph01 = ref({
      text: "Cabinetul nostru de stomatologie respectă și protejează confidențialitatea datelor personale ale pacienților și vizitatorilor site-ului. Această politică de confidențialitate explică în detaliu modul în care colectăm, utilizăm, stocăm și protejăm datele personale, precum și drepturile pe care le aveți în legătură cu aceste date. Prin utilizarea site-ului și serviciilor noastre, vă exprimați acordul cu prevederile acestei politici de confidențialitate.",
    });

    const titleText01 = ref({
      title: "Colectarea datelor personale",
      titleType: 3,
      paragraphs: [
        "Colectăm date personale în următoarele situații:",
        "1. Când vă creați o programare online prin completarea și trimiterea formularului de pe site",
        "2. Când vă prezentați la cabinet și faceți o programare direct, fie verbal, fie prin completarea unui formular scris.",
      ],
    });

    const paragraph02 = ref({
      text: "Datele personale pe care le colectăm includ: numele, prenumele, numărul de telefon, adresa de e-mail, preferințele de dată și oră pentru programare, informații despre urgența programării și eventuale informații medicale relevante, dacă pacientul își dă acordul verbal.",
    });

    const titleText02 = ref({
      title: "Utilizarea datelor personale",
      titleType: 3,
      paragraphs: [
        "Folosim datele personale colectate în următoarele scopuri:",
        "1. Pentru a vă oferi servicii de stomatologie și a ne asigura că programările sunt gestionate în mod eficient.",
        "2. Pentru a vă contacta în legătură cu programările, modificări ale acestora sau alte informații relevante despre serviciile noastre.",
        "3. Pentru a îmbunătăți calitatea serviciilor noastre și a ne adapta mai bine nevoilor pacienților.",
        "4. Pentru a ne îndeplini obligațiile legale și a respecta reglementările aplicabile în domeniul sănătății și protecției datelor.",
      ],
    });

    const titleText03 = ref({
      title: "Stocarea datelor personale",
      titleType: 3,
      text: "Datele personale colectate sunt stocate într-o bază de date securizată - Firestore Database. Aceasta asigură protecția și confidențialitatea datelor și este accesibilă doar de către personalul autorizat al cabinetului nostru de stomatologie.",
    });

    const titleText04 = ref({
      title: "Securitatea datelor personale",
      titleType: 3,
      text: "Ne angajăm să protejăm securitatea datelor personale și să prevenim accesul neautorizat, divulgarea, modificarea sau distrugerea acestora. Implementăm măsuri tehnice și organizatorice adecvate pentru a asigura un nivel de securitate corespunzător, ținând cont de natura datelor și riscurile asociate cu prelucrarea acestora.",
    });

    const titleText05 = ref({
      title: "Drepturile dvs. în legătură cu datele personale",
      titleType: 3,
      paragraphs: [
        "Conform reglementărilor privind protecția datelor, aveți următoarele drepturi:",
        "1. Dreptul de acces: puteți solicita o copie a datelor personale pe care le deținem despre dvs.",
        "2. Dreptul la rectificare: puteți solicita corectarea oricăror erori în datele personale pe care le deținem despre dvs.",
        "3. Dreptul la ștergere: puteți solicita ștergerea datelor personale pe care le deținem despre dvs., în anumite condiții.",
        "4. Dreptul la restricționarea prelucrării: puteți solicita restricționarea prelucrării datelor dvs. personale în anumite situații.",
        "5. Dreptul la portabilitatea datelor: puteți solicita să vă furnizăm datele personale într-un format structurat, utilizat în mod curent și lizibil de mașină, sau să le transferăm unui alt controlor de date, dacă este posibil din punct de vedere tehnic.",
        "6. Dreptul la obiecție: puteți obiecta în legătură cu prelucrarea datelor dvs. personale în anumite circumstanțe.",
      ],
    });

    const titleText06 = ref({
      title: "Actualizări ale politicii de confidențialitate",
      titleType: 3,
      paragraphs: [
        "Ne rezervăm dreptul de a actualiza periodic această politică de confidențialitate pentru a reflecta schimbările în practicile noastre de prelucrare a datelor sau în reglementările aplicabile. Vă recomandăm să consultați această pagină în mod regulat pentru a fi la curent cu cele mai recente informații privind modul în care protejăm confidențialitatea datelor personale.",
      ],
    });

    const titleText07 = ref({
      title: "Contact",
      titleType: 3,
      paragraphs: [
        "Dacă aveți întrebări, nelămuriri sau solicitări legate de prelucrarea datelor personale și de această politică de confidențialitate, vă rugăm să ne contactați prin intermediul informațiilor de contact furnizate pe site.",
      ],
    });

    const title01 = ref({
      title: "Concluzie",
      titleType: 3,
    });

    const paragraph03 = ref({
      text: "Respectăm și protejăm confidențialitatea datelor personale ale pacienților și vizitatorilor site-ului nostru. Ne angajăm să colectăm, să utilizăm și să stocăm datele personale într-un mod responsabil și transparent, în conformitate cu reglementările aplicabile în domeniul protecției datelor și sănătății. Prin folosirea site-ului și serviciilor noastre, vă exprimați acordul cu această politică de confidențialitate și vă asumați responsabilitatea de a respecta prevederile acesteia.",
    });

    const paragraph04 = ref({
      text: "Ne angajăm să comunicăm în mod clar și deschis cu pacienții și vizitatorii site-ului nostru despre colectarea, utilizarea și stocarea datelor lor personale și să le oferim posibilitatea de a-și exercita drepturile în legătură cu aceste date.",
    });

    const paragraph05 = ref({
      text: "Dacă aveți întrebări sau preocupări legate de politica noastră de confidențialitate sau de prelucrarea datelor dvs. personale, vă încurajăm să ne contactați folosind informațiile de contact furnizate pe site. Suntem dedicați protejării confidențialității datelor dvs. personale și vom răspunde prompt și profesionist la orice solicitări sau preocupări pe care le aveți.",
    });

    const paragraph06 = ref({
      text: "În final, ne asumăm responsabilitatea de a ne informa pacienții și vizitatorii site-ului nostru despre orice schimbări în politica noastră de confidențialitate și de a asigura că aceste modificări sunt comunicate în mod clar și în timp util. Vă mulțumim pentru încrederea pe care ne-o acordați în ceea ce privește protejarea confidențialității datelor dvs. personale și ne angajăm să ne menținem în continuare angajamentul față de protejarea și respectarea drepturilor dvs. privind datele personale.",
    });

    return {
      headImg,
      headTitle,
      subtitle,
      paragraph01,
      paragraph02,
      titleText01,
      titleText02,
      titleText03,
      titleText04,
      titleText05,
      titleText06,
      titleText07,
      title01,
      paragraph03,
      paragraph04,
      paragraph05,
      paragraph06,

    };
  },
};
</script>