<template>
  <PageHead :img="headImg" :title="headTitle" :subtitle="subtitle" />
  <div class="container-white-background">
    <div class="container contact-page">
      <Separator size="75" />
      <TextSimple :comp="{
        // text: 'Cabinetul nostru de stomatologie se străduiește să ofere cele mai bune servicii și tratamente stomatologice personalizate pentru fiecare pacient în parte. Echipa noastră de medici stomatologi este formată din profesioniști cu experiență, care lucrează cu cele mai noi tehnologii și materiale pentru a oferi tratamente dentare de înaltă calitate. Ne-am dori să fim partenerul dumneavoastră de încredere în îngrijirea sănătății orale și să vă ajutăm să obțineți un zâmbet sănătos și strălucitor. De la detartraj și periaj profesional la tratamente de albire și implanturi dentare, suntem aici pentru a vă oferi cele mai bune soluții pentru nevoile dumneavoastră dentare. Pentru a afla cum putem fi de ajutor, vă invităm să ne contactați prin formularul de mai jos, la adresa de email sau la numărul de telefon din subsolul paginii.',
        text: `Dedicarea și pasiunea noastră se reflectă în calitatea serviciilor oferite, obținute prin utilizarea de tehnologii avansate și metode moderne.Viziunea noastră este de a deveni aliatul dumneavoastră de încredere pe drumul spre un zâmbet strălucitor. În oferta noastră puteți găsi o gamă largă de proceduri, de la cele de bază până la soluții avansate. Pentru a afla mai multe informații sau pentru a vă programa o ședință cu noi, nu ezitați să ne contactați la numărul de telefon ${phoneNumber} sau la adresa de email ${emailAddress}`,
      }" />
      <!-- <Separator size="75" />
      <ClassicTeaser :teaser="firstTeaser" />
      <Separator size="75" />
      <ClassicTeaser :teaser="secondTeaser" /> -->
      <!-- <Separator size="100" />
      <Appointment /> -->
      <Separator size="100" />
      <FAQ />
      <Separator size="50" />
    </div>
  </div>
</template>
  
<script>
import { ref } from "@vue/reactivity";
import Appointment from "../components/Appointment.vue";
import FAQ from "../components/FAQ.vue";
import PageHead from "../components/PageHead.vue";
import Separator from "@/components/Separator.vue";

import ClassicTeaser from "../components/subcomponents/ClassicTeaser.vue";
import TextSimple from "@/components/subcomponents/TextSimple.vue";

import { useContactStore } from "@/stores/ContactStore";

export default {
  components: {
    Appointment,
    PageHead,
    ClassicTeaser,
    FAQ,
    Separator,
    TextSimple,
  },
  setup() {
    const phoneNumber = useContactStore().phoneNumber.value;
    const phoneNumberDisplayed = useContactStore().phoneNumber.displayed;
    const emailAddress = useContactStore().emailAddress.value;
    // #############################
    // Content displayed on the website
    // #############################
    const headImg = ref(
      `https://images.unsplash.com/photo-1595087012935-124877078142?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2574&q=80`
    );
    const headTitle = ref(`Unde ne găsiți?`);
    const subtitle = ref("Cum puteți intra în contact cu VisoDent");
    // #############################
    // #############################

    const firstTeaser = ref({
      alt: "Imagine cu doctori",
      image:
        "https://images.unsplash.com/photo-1606913536019-510fdc8f775e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2525&q=80",
      paragraphs: [
        "Curabitur rhoncus orci vel nisl condimentum, sed consectetur enim porttitor. Praesent tortor mi, tempor sit amet suscipit sit amet, interdum ut lacus. Integer gravida sapien ultricies, pulvinar velit a, tincidunt metus. Sed eu ligula non odio fringilla egestas sed et ex. Praesent non elit sed erat interdum malesuada in vel lectus.",
      ],
      title: "Title first paragraph",
      isLeft: true,
    });
    const secondTeaser = ref({
      alt: "Imagine cu doctori",
      image:
        "https://images.unsplash.com/photo-1606913536019-510fdc8f775e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2525&q=80",
      paragraphs: [
        "Cras nulla purus, aliquam eget sollicitudin id, laoreet a mi. Morbi porttitor ullamcorper nulla, at sodales velit eleifend ac. Fusce sagittis auctor metus, nec euismod lectus sollicitudin non. Vestibulum ante ligula, mollis eleifend sem vel, fermentum faucibus mauris. Donec sagittis imperdiet risus, id finibus risus pharetra in.",
      ],
      title: "Title first paragraph",
      isLeft: false,
    });

    return { headImg, subtitle, headTitle, firstTeaser, secondTeaser, phoneNumber, phoneNumberDisplayed, emailAddress };
  },
};
</script>