import { defineStore } from "pinia";

export const useContactStore = defineStore("ContactStore", {
  state: () => ({
    address: "Brăila",
    emailAddress: {
      displayed: "contact@visodent.ro",
      value: "contact@visodent.ro",
    },
    phoneNumber: {
      displayed: "0723 665 877",
      value: "0723665877",
    },
  }),
});
