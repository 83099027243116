<template>
  <NavBar />
  <div class="navbar-placeholder"></div>
  <router-view />
  <Footer />
  <div class="screen-media-width" v-if="false">
    <!-- TO DELETE -->
    <div class="tiny-p">
      <p>tiny</p>
      <span>0-480</span>
    </div>
    <div class="small-p">
      <p>small</p>
      <span>481-768</span>
    </div>
    <div class="medium-p">
      <p>medium</p>
      <span>769-1024</span>
    </div>
    <div class="large-p">
      <p>large</p>
      <span>1025-1440</span>
    </div>
    <div class="xlarge-p">
      <p>xlarge</p>
      <span>1441-2000</span>
    </div>
    <div class="xxlarge-p">
      <p>xxlarge</p>
      <span>2000-3000</span>
    </div>
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Footer from "./components/Footer.vue";
import Separator from "./components/Separator.vue";

export default {
  name: "App",
  components: {
    NavBar,
    Footer,
    Separator,
  },
};
</script>

<style lang="scss">
@import "./static/style/main.scss";
</style>
