import { ref } from "vue";
import { db } from "../../firebase/config";

const useCollection = (collection) => {
  const error = ref(null);

  const addDoc = async (doc) => {
    error.value = null;

    try {
      const docRef = await db.collection(collection).add(doc);
      const docId = docRef.id;
      await docRef.update({ id: docId });
    } catch (err) {
      console.log(err.message);
      error.value = "404";
    }
  };

  return { error, addDoc };
};

export default useCollection;
