<template>
  <div id="ideas">
    <p class="idea">
      Bine ati venit pe pagina a website-ului cabinetului nostru stomatologic.
      Suntem un grup dedicat de medici si asistenti stomatologici care suntem
      pasionati de sanatatea dentara si frumusetea zambetului. Cu o experienta
      de peste x ani in domeniu si cu cele mai moderne echipamente si tehnici,
      ne asiguram ca pacientii nostri primesc cele mai bune servicii
      stomatologice. Echipa noastra se straduieste sa ofere un ambient relaxant
      si confortabil pentru pacienti, si ne asiguram ca fiecare pacient se simte
      in siguranta si respectat. Suntem dedicati sa oferim servicii
      individualizate si personalizate pentru fiecare pacient in parte, astfel
      incat sa ne putem atinge obiectivul comun de a avea un zambet sanatos si
      frumos. Ne bucuram sa va cunoastem si sa va putem ajuta cu nevoile
      dentare. Nu ezitati sa ne contactati pentru a programa o vizita sau pentru
      a afla mai multe despre serviciile noastre.
    </p>
    <p class="idea">
      Suntem fericiti sa va intampinam pe website-ul nostru al cabinetului
      stomatologic! Cabinetul nostru este dedicat sa ofere cele mai bune
      servicii stomatologice si tratamente pentru pacientii nostri. Personalul
      nostru experimentat si atent va va asigura ca va simtiti confortabil si in
      siguranta in timpul tratamentului. Cu o gama larga de servicii, de la
      tratamentul cariilor si albirea dintilor, la implanturi si chirurgie
      orala, va putem ajuta sa aveti un zambet frumos si sanatos. Nu ezitati sa
      ne contactati pentru a va programa o vizita sau pentru a afla mai multe
      despre serviciile noastre.
    </p>
    <p class="idea">
      Bine ati venit la cabinetul nostru stomatologic! Suntem incantati sa va
      anuntam deschiderea cabinetului in luna martie a acestui an. Cabinetul
      nostru este condus de un singur medic stomatolog experimentat si dedicat,
      care se va asigura ca beneficiati de cele mai bune servicii stomatologice.
      Va asteptam sa ne vizitati pentru a va oferi tratamente de calitate si
      pentru a va ajuta sa aveti un zambet frumos si sanatos.
    </p>
    <p class="idea">
      Suntem incantati sa va intampinam pe website-ul cabinetului nostru
      stomatologic. Suntem un grup dedicat de medici si asistenti stomatologici
      care suntem pasionati de sanatatea dentara si frumusetea zambetului. Va
      invitam sa explorati website-ul nostru pentru a afla mai multe despre
      serviciile noastre si pentru a va programa o vizita la cabinetul nostru,
      fie ca este vorba de un simplu consult sau o urgenta stomatologica. Nu
      ezitati sa ne contactati daca aveti intrebari sau nevoi speciale. Asteptam
      cu nerabdare sa va cunoastem si sa va putem ajuta cu nevoile dentare,
      indiferent de situatie.
    </p>
    <ul class="idea">
      <li>Consultatii stomatologice generale</li>
      <li>Tratament carii dentare</li>
      <li>Albirea dintilor</li>
      <li>Lucrari protetice (punti, coroane, proteze)</li>
      <li>Chirurgie orala (extractii, implanturi dentare)</li>
      <li>Endodontie (tratamentul canalelor radiculare)</li>
      <li>Periodontologie (tratamentul gingivitei si a bolilor parodontale)</li>
      <li>
        Estetica dentara (conturarea si albirea dintilor, fatetele dentare)
      </li>
      <li>Ortodontie (tratamentul malocluziilor dentare, aparate dentare)</li>
      <li>Tratamentul halenei (halena, rau miros al gurii)</li>
      <li>Pedodontie (stomatologie pentru copii)</li>
      <li>Stomatologie cosmetica (conturarea buzelor, marirea buzelor)</li>
      <li>Tratamentul obturatiilor</li>
      <li>Tratamentul gingiilor</li>
      <li>Tratamentul cariilor si obturatiilor</li>
      <li>Tratamentul durerilor de dinti</li>
      <li>Tratamentul dintilor sensibili</li>
      <li>Tratamentul dintilor de lapte</li>
      <li>Tratamentul dintilor scheletici</li>
      <li>Tratamentul dintilor nervosi</li>
    </ul>
    <p class="idea">
      Bine ați venit la cabinetul nostru stomatologic! Dacă aveți nevoie de
      îngrijiri dentare sau dacă doriți să preveniți problemele dentare, vă
      invităm să ne contactați pentru a programa o consultație. Noi suntem aici
      pentru a vă oferi cele mai bune servicii și tratamente dentare. Nu ezitați
      să ne sunați sau să completați formularul de programare online, iar noi
      vom fi bucuroși să vă ajutăm. Așteptăm cu nerăbdare să vă cunoaștem și să
      începem drumul către un zâmbet sănătos și strălucitor!
    </p>
    <p class="idea">
      Bun venit la cabinetul nostru stomatologic! Suntem aici pentru a vă
      întâmpina cu un zâmbet călduros și a vă oferi cele mai bune servicii
      dentare. Dacă aveți nevoie de îngrijiri dentare sau doriți să preveniți
      problemele dentare, vă invităm să ne contactați cu încredere. Programarea
      unei consultații este simplă și poate fi făcută prin telefon sau prin
      completarea formularului nostru de programare online. Noi înțelegem cât de
      important este să vă simțiți confortabil și în siguranță în timpul
      tratamentului dentar, așa că ne asigurăm că fiecare experiență la
      cabinetul nostru este cât se poate de plăcută. Așteptăm cu nerăbdare să vă
      cunoaștem și să vă ajutăm să aveți un zâmbet sănătos și strălucitor!
    </p>
    <div class="idea">
      <h3>Pagina de contact:</h3>
      <ul>
        <li>Adresa cabinetului, inclusiv orașul și codul poștal</li>
        <li>
          Numerele de telefon și adresa de email pentru a contacta cabinetul
        </li>
        <li>Orele de program ale cabinetului</li>
        <li>O hartă cu locația cabinetului</li>
        <li>
          Un formular de contact pentru pacienți, în care aceștia pot transmite
          întrebări sau solicitări direct către cabinet
        </li>
        <li>Informatii despre parcarea disponibila in zona cabinetului</li>
        <li>O listă cu asigurările dentare acceptate de cabinet</li>
        <li>Link-uri către rețelele sociale ale cabinetului</li>
        <li>
          O secțiune cu întrebări frecvente pentru a ajuta pacienții să găsească
          rapid informațiile de care au nevoie.
        </li>
      </ul>
    </div>
    <div class="idea">
      <h3>FAQ</h3>
      <ul>
        <li>Care sunt orele de program ale cabinetului?</li>
        <li>Ce tipuri de servicii dentare sunt oferite de cabinet?</li>
        <li>Care sunt costurile serviciilor dentare?</li>
        <li>Cabinetul acceptă asigurările dentare?</li>
        <li>
          Ce se întâmplă dacă am o urgență dentară în afara programului de lucru
          al cabinetului?
        </li>
        <li>Cât de departe este cabinetul de locația mea?</li>
        <li>Există parcare disponibilă în apropierea cabinetului?</li>
        <li>Cât durează o vizită la cabinet?</li>
        <li>Ce se întâmplă dacă am teamă de dentist?</li>
        <li>Ce trebuie să fac înainte de o vizită la cabinet?</li>
        <li>
          Ce se întâmplă dacă am o problemă cu tratamentul dentar după ce
          părăsesc cabinetul?
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#ideas {
  background-color: white;
  width: 100%;
  .idea {
    margin: auto;
    max-width: 500px;
    margin-bottom: 50px;
  }
}
</style>