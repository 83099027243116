<template>
  <div class="cmp-service-price">
    <span class="service-wrapper">
      <span class="service-id">{{ service.id }}</span>
      <TextParagraphs :comp="{ paragraphs: [service.name] }" class="cmp-service-price__name" />
    </span>
    <TextParagraphs :comp="{ paragraphs: [service.price + ' ' + service.currency] }" class="cmp-service-price__price" />
  </div>
</template>

<script>
import TextParagraphs from "@/components/subcomponents/TextParagraphs.vue";
export default {
  components: { TextParagraphs },
  props: ["id", "service"],
};
</script>