<template>
  <div class="cmp-faq">
    <Title :comp="{title: 'Cele mai frecvente întrebări', titleType:'2'}"/>
    <ul class="cmp-faq__list">
      <Question
        class="cmp-faq__item"
        v-for="(item, index) in questions"
        :key="index"
        :item="item"
      />
    </ul>
  </div>
</template>

<script>
import { useFAQStore } from "@/stores/FAQStore";
import Question from "./FAQquestion.vue";
import Title from "./subcomponents/Title.vue";

export default {
  components: { Question, Title },

  setup() {
    const questions = useFAQStore().questions;
    return { questions };
  },
};
</script>