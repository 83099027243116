<template>
  <div class="cmp-title">
    <h1 v-if="comp.titleType == 1">{{ comp.title }}</h1>
    <h2 v-else-if="comp.titleType == 2">{{ comp.title }}</h2>
    <h3 v-else-if="comp.titleType == 3">{{ comp.title }}</h3>
    <h4 v-else-if="comp.titleType == 4">{{ comp.title }}</h4>
    <h5 v-else>{{ comp.title }}</h5>
  </div>
</template>

<script>
export default {
  props: ["comp"],
};
</script>